import React, { useState } from 'react'
import backgroundImg from '../images/portrait.jpg'
import '../styles/construction.css'

export default function Construction() {
    const [email, setEmail] = useState(``)
    const handleSubmit = () => email.trim() !== `` && setEmail(``)

    return (
        <div className="main-area">
		
            <section className="left-section" style={{ backgroundImage: `url(${backgroundImg})` }}>
			
            </section>
		
            <section className="right-section full-height">
		
                <a className="logo" href="/"><img src="images/logo.svg" alt="Logo"/></a>
			
                <div className="display-table">
                    <div className="display-table-cell">
                        <div className="main-content">
                            <h1 className="title text-white"><b>Under Construction</b></h1>
                            <p className="desc">Our website is currently undergoing scheduled maintenance.
							We Should be back shortly. Thank you for your patience.</p>

                            <div className="email-input-area">
                                <input className="email-input text-black" onChange={e => setEmail(e.target.value)} name="email" value={email} type="text" placeholder="Enter your email" />
                                <button className="submit-btn" name="submit" onClick={handleSubmit}><b>NOTIFY US</b></button>
                            </div>
						
                            <p className="post-desc">Sign up now to get early notification of our lauch date!</p>
                        </div>
                    </div>
                </div>
			
                <ul className="footer-icons">
                    <li className="py-6 flex sm:flex-row flex-col">
                        <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
                            <a className="text-gray-500">
                                <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-8 h-8" viewBox="0 0 24 24">
                                    <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                                </svg>
                            </a>
                            <a className="ml-4 text-gray-500">
                                <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-8 h-8" viewBox="0 0 24 24">
                                    <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                                </svg>
                            </a>
                            <a className="ml-4 text-gray-500">
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-8 h-8" viewBox="0 0 24 24">
                                    <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                                    <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                                </svg>
                            </a>
                            <a className="ml-4 text-gray-500">
                                <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0" className="w-8 h-8" viewBox="0 0 24 24">
                                    <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                                    <circle cx="4" cy="4" r="2" stroke="none"></circle>
                                </svg>
                            </a>
                        </span>
                    </li>
                </ul>
		
            </section>
		
        </div>
    )
}
